import { BlogContainer } from 'components/blogitem';
import Layout from 'components/layout';
import PageHeader from 'components/pageheader';
import Pagination from 'components/pagination';
import Container from 'components/ui/container';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useIntl } from 'react-intl';

export default function BlogListTemplate({
    data,
    pageContext,
}: {
    data: GatsbyTypes.BlogListingQuery;
    pageContext: {
        limit: number;
        skip: number;
        numPages: number;
        currentPage: number;
    };
}) {
    const blogItems = data.blog.nodes.map((item) => ({
        id: item.id,
        image: (item.frontmatter?.image?.childImageSharp
            ?.gatsbyImageData as unknown) as IGatsbyImageData,

        url: item.fields?.slug as string,
        date: item.frontmatter?.date as string,
        title: item.frontmatter?.title as string,
    }));

    const intl = useIntl();

    return (
        <Layout
            seo={{
                title: intl.formatMessage({
                    id: 'blog',
                }),
            }}
        >
            <PageHeader
                image={
                    (data.banner?.frontmatter?.blogListingBanner
                        ?.childImageSharp
                        ?.gatsbyImageData as unknown) as IGatsbyImageData
                }
                title={intl.formatMessage({
                    id: 'blog',
                })}
            />
            <div className="py-16">
                <Container>
                    <BlogContainer data={blogItems} />
                </Container>
                <div className="my-8">
                    <Pagination
                        currentPage={pageContext.currentPage}
                        totalPages={pageContext.numPages}
                        type="blog"
                    />
                </div>
            </div>
        </Layout>
    );
}

export const query = graphql`
    query BlogListing($locale: String!, $limit: Int!, $skip: Int!) {
        blog: allMdx(
            filter: {
                fields: { sourceName: { eq: "blog" }, locale: { eq: $locale } }
            }
            skip: $skip
            limit: $limit
        ) {
            nodes {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    date(formatString: "DD MMM, YYYY")
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        banner: mdx(fields: { sourceName: { eq: "options" } }) {
            frontmatter {
                blogListingBanner {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;
